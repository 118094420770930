<template>
  <div style="--el-color-danger:#F33B50;">
    <el-form ref="registerFormRef" :model="registryForm" label-position="top">
      <template v-if="!isVerify">
        <el-form-item
          label="" prop="email"
          :rules="[
            { validator: checkEmail, trigger: 'manual' }
          ]">
          <el-input ref="emailInputRef" v-model.trim="registryForm.email" class="form-input" placeholder="Email"></el-input>
          <span v-if="isEmailRegistered" class="c-#F33B50 text-12px">Email has been registered ，please <span
            class="cp c-#6165F6" @click="handleClick('signin')">Sign in</span> </span>
        </el-form-item>

        <el-form-item
          :rules="[{ min: 6, max: 16, message: 'Length should be 6 to 16', trigger: 'manual' }]" prop="password">
          <input-password v-model="registryForm.password" :maxlength="16" :minlength="6" @keydown.enter="onKeyDown"/>
        </el-form-item>
        <el-button
          :disabled="!(registryForm.email && registryForm.password)" class="form-submit mt-16px flex-center font-600"
          @click="handleRegisterStep1">
          Sign Up
          <svg-icon v-if="isLoading" size="24" name="loading"></svg-icon>
        </el-button>

      </template>

      <template v-if="isVerify">
        <div class="bg-#F7F8FA p-16px rounded-16px text-16px">
          <div class="c-#616478">Email <span class="c-#B7B9C9 ml-6px">{{ registryForm.email }}</span></div>
        </div>
        <div class="text-12px c-#616478 mb-8px mt-12px">Please enter the confirmation code we sent to your email.</div>
        <el-form-item label="" prop="verify_code" :rules="[{ validator: checkCode, trigger: 'manual' }]">
          <input-code ref="inputCodeRef" v-model="registryForm.verify_code" @sendCode="handleSendCode" />
        </el-form-item>
        <el-button
          class="form-submit mt-12px flex-center font-600" :disabled="!isValidCode(registryForm.verify_code)"
          @click="handleRegister">
          <span class="mr-10px">Verify and Sign in</span>
          <svg-icon v-if="isLoading" size="24" name="loading"></svg-icon>
        </el-button>
      </template>
    </el-form>
  </div>
</template>

<script setup lang="ts">
	import type { FormInstance } from 'element-plus';
	import { checkEmailStatus, sendEmailVerifyCode, registerWithEmail } from '~/apis/user';

	const registryForm = reactive({
		email: '',
		password: '',
		verify_code: ''
	});

	const emit = defineEmits(['verify', 'signin']);
	const isVerify = ref(false);
	const inputCodeRef = ref();
	const isRegistered = ref(false);
	const isLoading = ref(false);
	const emailInputRef = ref<HTMLInputElement | null>(null);

	// handleClick('signup')
	const handleClick = (type: string) => {
		if (type === 'signup') {
			isVerify.value = true;
			checkEmailStatus(registryForm.email).then(() => {
				inputCodeRef.value?.sendCode();
			}).catch(() => {
				isRegistered.value = true;
			});
		} else if (type === 'signin') {
			emit('signin');
		}
	};

	const handleSendCode = () => {
		sendEmailVerifyCode(registryForm.email);
	};

	function handleRegister () {
		if (isLoading.value) {
			return;
		}
		isLoading.value = true;
		reportEvent('User_Register_Click', {
			entry_type: 'email'
		});
		registerWithEmail(registryForm).then(() => {
			setLoginUser({
				reg_way: 'email',
				email: registryForm.email,
				password: registryForm.password
			}).then(() => {
				isLoading.value = false;
				if (window.localStorage.getItem('next')) {
					const _next = window.localStorage.getItem('next') as string;
					window.localStorage.removeItem('next');
					window.location.href = _next;
				} else {
					window.location.href = '/user/dashboard';
				}
			}).catch((err) => {
				console.log(err);
				isLoading.value = false;
			});
		}).catch((err) => {
			isLoading.value = false;
		});
	}

	const isEmailRegistered = ref(false);
	const registerFormRef = ref<FormInstance>();

	async function handleRegisterStep1 () {
		await registerFormRef.value?.validate((valid, fields) => {
			if (valid) {
				isVerify.value = true;
				setTimeout(() => {
					inputCodeRef.value?.sendCode();
				}, 1000);
			}
		});
	}

	function checkEmail (rule: any, value: any, callback: any) {
		isEmailRegistered.value = false;
		if (!value) {
			callback(new Error('Email is required!'));
			return;
		}
		checkEmailStatus(value).then((res) => {
			if (res.exists) {
				isEmailRegistered.value = true;
				callback(new Error());
				return;
			}
			callback();
		}).catch((err) => {
			if (err.code === 40000) {
				callback(new Error('Invalid email address'));
				return;
			}
			callback();
		});
	}

	function isValidCode (str: string) {
		const numberPattern = /^\d+$/;
		if (str.length !== 6) {
			return false;
		}
		if (!numberPattern.test(str)) {
			return false;
		}
		return true;
	}

	function checkCode (rule: any, value: any, callback: any) {
		if (!value) {
			callback(new Error('Verification code is required!'));
			return;
		}
		if (!isValidCode(value)) {
			callback(new Error('Invalid Verification code'));
			return;
		}
		callback();
	}

	const onKeyDown = (event: any) => {
		event.stopPropagation();
		event.preventDefault();
		if (!registryForm.email.trim() || !registryForm.email.trim()) {
			return;
		}
		handleRegisterStep1();
	};

	onMounted(() => {
		emailInputRef.value?.focus();
	});

</script>

<style scoped></style>
