<template>
  <div style="--el-color-danger:#F33B50;">
    <el-form ref="loginFormRef" :model="loginForm" label-position="top">
      <el-form-item
        label="" prop="email"
        :rules="[
          { validator: checkEmail, trigger: 'manual' }
        ]">
        <el-input ref="emailInputRef" v-model.trim="loginForm.email" class="form-input" placeholder="Email"></el-input>
        <span v-if="!isEmailRegistered" class="c-#F33B50 text-12px leading-18px">Email has not been registered, please <span
          class="cp c-#6165F6" @click="handleClick('signup')">Sign up</span> </span>
      </el-form-item>
      <div class="flex-between text-12px mb-8px mt-12px">
        <span class="c-#616478"></span>
        <nuxt-link :to="`/user/reset-password?email=${loginForm.email}`" class="cp c-#828499 decoration-none">Forget the
          password?</nuxt-link>
      </div>
      <el-form-item :rules="[{ min: 6, max: 16, message: 'Length should be 6 to 16', trigger: 'manual' }]" prop="password" @keydown.enter="onKeyDown">
        <input-password v-model="loginForm.password" :maxlength="16" :minlength="6" />
      </el-form-item>
      <el-button :disabled="!(loginForm.email&&loginForm.password)" class="form-submit mt-12px flex-center font-600" @click="handleLogin">
        Sign in
        <svg-icon v-if="isLoading" size="24" name="loading"></svg-icon>
      </el-button>
    </el-form>
  </div>
</template>

<script setup lang="ts">
	import type { FormInstance } from 'element-plus';
	import { checkEmailStatus } from '~/apis/user';

	const route = useRoute();
	const loginForm = reactive({
		email: '',
		password: ''
	});

	const isEmailRegistered = ref(true);
	const loginFormRef = ref<FormInstance>();
	const emailInputRef = ref<HTMLInputElement | null>(null);

	const emit = defineEmits(['signup']);
	const handleClick = (type: string) => {
		if (type === 'signup') {
			emit('signup');
		}
	};

	const isLoading = ref(false);
	// const next = route.query.next as string;
	const url = route.fullPath;
	const nextParam = extractNextParam(url);
	function extractNextParam (url:string) {
		const nextIndex = url.indexOf('next=');
		const len = 'next='.length;
		if (nextIndex !== -1) {
			return url.substring(nextIndex + len).replace(/&/, '?');// Return the substring starting from 'next='
		}
		return ''; // Return '' if 'next=' is not found
	}
	async function handleLogin () {
		await loginFormRef.value?.validate((valid, fields) => {
			if (valid) {
				if (isLoading.value) {
					return;
				}
				reportEvent('User_Login_click', {
					entry_type: 'email'
				});
				isLoading.value = true;
				setLoginUser({
					reg_way: 'email',
					email: loginForm.email,
					password: loginForm.password
				}).then(() => {
					const _next = nextParam || window.localStorage.getItem('next');
					if (_next) {
						window.localStorage.removeItem('next');
						window.location.href = _next;
					} else {
						window.location.href = '/en/home';
					}
				}).catch((err) => {
					console.log(err);
				}).finally(() => {
					isLoading.value = false;
				});
			} else {
				console.log('error submit!', fields);
			}
		});
	}

	function checkEmail (rule: any, value: any, callback: any) {
		isEmailRegistered.value = true;
		if (!value) {
			callback(new Error('Email is required!'));
			return;
		}
		checkEmailStatus(value).then((res) => {
			if (!res.exists) {
				isEmailRegistered.value = false;
				callback(new Error());
				return;
			}
			callback();
		}).catch((err) => {
			if (err.code === 40000) {
				callback(new Error('Invalid email address'));
				return;
			}
			callback();
		});
	}

	const onKeyDown = (event: any) => {
		event.stopPropagation();
		event.preventDefault();
		if (!loginForm.email.trim() || !loginForm.email.trim()) {
			return;
		}
		handleLogin();
	};

	onMounted(() => {
		emailInputRef.value?.focus();
	});
</script>

<style scoped></style>
