<template>
  <div>
    <div v-if="type === 'login' || isDialog" class="text-center c-#282A37 fs-32 font-600 mb-24px">
      {{ type === 'login' ? 'Sign in' : 'Welcome to Arvin!' }}
    </div>
    <div
      id="signInGoogle"
      :class="type !== 'login' && !isDialog ? 'mt-12px':''"
      class="relative h-48px hover:opacity-80 cp rounded-16px bg-#6165F6 c-white">
      <div id="googleIdSignIn" class="absolute top-4px opacity-0 z-1" :style="scaleStyle"></div>
      <div class="flex-center h-full w-full z-0">
        <svg-icon name="google-login" class="mr-12px"></svg-icon>
        <span class="text-16px font-600">Continue with Google</span>
      </div>
    </div>
    <div
      id="microsoftIdSignIn" class="bg-#F3F3FF flex-center mt-16px h-48px hover:opacity-80 cp rounded-16px"
      @click="msalLogin">
      <svg-icon name="microsoft-login" class="mr-12px"></svg-icon>
      <span class="text-16px c-#444658 font-600">Continue with Microsoft</span>
    </div>
    <el-divider class="my-20px!">or</el-divider>
    <email-login v-if="type==='login'" @signup="handleToggle('register')" />
    <email-registry v-if="type==='register'" @signin="handleToggle('login')" />
    <Loading v-if="loading" />
    <div class="mt-24px">
      <template v-if="type === 'login'">
        <div class="text-center c-#828499 fs-12">Don't have an account?</div>
        <div class="text-center c-#828499 fs-12"><span class="cp c-#6165F6 font-600" @click="handleToggle('register')">Create an account for free</span> to get queries and more features</div>
      </template>
      <template v-else>
        <div class="text-center c-#828499 fs-12">Already have an account?</div>
        <div class="cp text-center c-#6165F6 fs-12 font-600" @click="handleToggle('login')">Sign in</div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
	import EmailLogin from './EmailLogin.vue';
	import EmailRegistry from './EmailRegistry.vue';

	const props = defineProps({
		isDialog: {
			type: Boolean,
			default: false
		}
	});
	const route = useRoute();
	const next = route.query.next as string;
	const config = useNuxtApp().$arvinConfig as any;
	const emit = defineEmits(['changeType']);
	const url = route.fullPath;
	const nextParam = extractNextParam(url);
	function extractNextParam (url:string) {
		const nextIndex = url.indexOf('next=');
		const len = 'next='.length;
		if (nextIndex !== -1) {
			return url.substring(nextIndex + len).replace(/&/, '?');// Return the substring starting from 'next='
		}
		return ''; // Return '' if 'next=' is not found
	}
	const { googleLoginInit, loading } = useGoogleLogin(() => {
		if (next) {
			window.location.href = nextParam;
		} else {
			if (window.localStorage.getItem('next')) {
				const _next = window.localStorage.getItem('next') as string;
				window.localStorage.removeItem('next');
				window.location.href = _next;
			} else {
				if (props.isDialog) {
					window.location.reload();
				} else {
					window.location.href = '/en/home';
				}
			}
		}
	});
	function msalLogin () {
		reportEvent(type.value === 'login' ? 'User_Login_click' : 'User_Register_Click', {
			entry_type: 'microsoft'
		});
		if (next) {
			window.localStorage.setItem('next', nextParam);
		}
		window.location.href = config.bpMSLoginUrl;
	}
	const type = ref('login');
	const handleToggle = (_type: string) => {
		type.value = _type;
		emit('changeType', _type);
	};

	const scaleStyle = ref({
		transform: 'scaleX(1.1) scaleY(1.2)',
		left: '50px'
	});

	const computedScaleStyle = () => {
		const googleIdSignIn = document.getElementById('signInGoogle');
		const width = googleIdSignIn?.clientWidth;
		if (!width) { return; }
		const dWidth = (width - 200) / 200;
		const left = (width - 200) / 2;
		scaleStyle.value = {
			transform: `scaleX(${1 + dWidth}) scaleY(1.2)`,
			left: `${left}px`
		};
	};

	const observer = ref<ResizeObserver | null>(null);
	onMounted(() => {
		observer.value = new ResizeObserver(computedScaleStyle);
		observer.value.observe(document.getElementById('signInGoogle') as Element);
		// @ts-ignore
		if (window.google) {
			googleLoginInit();
		} else {
			// Load the google SDK
			const googleSdk = ['https://accounts.google.com/gsi/client'];
			asyncLoadScripts(googleSdk, () => {
				googleLoginInit();
			});
		}
		computedScaleStyle();
	});
	onUnmounted(() => {
		observer.value?.disconnect();
	});
</script>

<style lang="less" scoped>:deep(.el-divider__text) {
  color: #828499;
}</style>
